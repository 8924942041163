import { json, Outlet, useLoaderData, useRouteError } from '@remix-run/react'
import type { LoaderFunctionArgs } from '@remix-run/server-runtime'
import ErrorMessage from '~/components/error-message'
import Footer from '~/components/footer'
import NavWebsite from '~/components/navigation/nav-website'
import { getUser } from '~/models/users'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await getUser(request)
  return json({ user })
}

export default function WebsiteLayout() {
  const { user } = useLoaderData<typeof loader>()
  return (
    <div className='flex flex-col min-h-screen justify-between border-2'>
      <NavWebsite user={user} />
      <main className='p-6'>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error
  console.log(error)
  return <ErrorMessage error={error} />
}
