import { Link } from '@remix-run/react'
import type { User } from '@supabase/supabase-js'
import { Button } from '../ui/button'

const NavWebsite = ({ user }: { user: User | null }) => {
  return (
    <nav className='flex justify-between items-center w-full p-6'>
      <div>
        <h1 className='text-2xl font-bold'>
          <Link to='/'>Punchit.work</Link>
        </h1>
      </div>
      <div>
        {user ? (
          <Link to='/u'>
            <Button>Dashboard</Button>
          </Link>
        ) : (
          <>
            <Button asChild variant='outline' className='mr-2'>
              <Link to='/login'>Login</Link>
            </Button>
            <Button asChild variant='outline'>
              <Link to='/signup'>Register</Link>
            </Button>
          </>
        )}
      </div>
    </nav>
  )
}

export default NavWebsite
