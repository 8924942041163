import { Link } from '@remix-run/react'

const Footer = () => {
  return (
    <footer className='p-2 w-full flex justify-center items-center'>
      <p className='text-sm text-gray-500'>
        &copy; {new Date().getFullYear()} Punchit |{' '}
        <Link to='/terms'>Terms & conditions</Link> |{' '}
        <Link to='/privacy'>Privacy</Link>
      </p>
    </footer>
  )
}

export default Footer
